<template>
	<v-card class="rounded-xl pa-6 text-center login-page" elevation="0" outlined>
		<v-form lazy-validation v-model="valid" @submit.prevent="submit">
			<v-row v-if="!send">
				<v-col cols="12">
					<h1>Account aanmaken</h1>
				</v-col>
				<v-col cols="12"><v-text-field class="theme-input" :rules="rules.mail" v-model="data.email" solo
						placeholder="Email" background-color="#f1f8fa" title="Email" type="text"></v-text-field></v-col>
				<v-col cols="0" md="2"></v-col>
				<v-col cols="12" md="4"><v-btn class="mx-auto d-block btn" color="themewhite"
						to="/auth">Terug</v-btn></v-col>
				<v-col cols="12" md="4"><v-btn class="text-white btn d-block w-100" color="themepurple"
						type="submit">Account aanmaken</v-btn></v-col>
			</v-row>
			<v-row v-if="send">
				<v-col cols="12">
					<h1>Account aanmaken</h1>
				</v-col>
				<v-col cols="12">
					<p>Accountaanvraag ontvangen, controleer uw inbox en spam voor de e-mail.</p>
				</v-col>
				<v-col cols="12" ><v-btn class="mx-auto d-block btn" elevation="0" to="/auth" text>Terug</v-btn></v-col>
			</v-row>
		</v-form>
	</v-card>
</template>
<script>
import api from "../../services/api";
import config from '../../config'
export default {
	data: () => ({
		data: {
			email: '',
		},
		send: false,
		loading: false,
		valid: false,
		rules: {
			mail: [
				(v) => !!v || "Verplicht",
				(v) => config.regex.mail.test(v) || "Incorrect e-mailadres",
				(v) => v.split('@', 2)[1]?.includes(".") || "Domeinextensie ontbreekt",
			],
			pass: [
				value => !!value || 'Verplicht.',
				value => (value && value.length >= 8) || 'Minimum 8 characters',
				value => (value && !(value.length > 64)) || 'Maximum 64 characters',
				value => (config.regex.password.test(value)) || 'Invalid password',
			]
		},

	}),
	methods: {
		submit() {
			if (this.valid) {
				this.loading = true
				api.post(`${config.url.api}/v1/auth/request`, this.data).then(() => {
					this.send = true;
				}).catch(() => {
					this.loading = false
					this.$toast.error('Ongeldig e-mailadres of server fout!')
				})
			}
		}
	},
}
</script>